import React, {Fragment} from 'react';


const Promise = () => (
  <Fragment>
    <section>
      Welcome to my part of the Web, on this site you will probably find stuff about tech, stuff about food and drink and stuff about my two homes. Enjoy!
    </section>
  </Fragment>
);

export default Promise;